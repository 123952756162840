
import {Vue, Component} from 'vue-property-decorator';
import FinesTable from '@/components/fines/FinesTable.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import AppStore from '@/components/AppStore';
import PoliciesTable from '@/components/policies/PoliciesTable.vue';

@Component({
  components: {PoliciesTable, AppTopBar, MenuTop, FinesTable}
})
export default class PoliciesView extends Vue {

  containerWidth = AppStore.containerWidth;

  mounted() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    AppStore.setDisplayModeTable();
  }

}
