
import {Vue, Component, Prop} from 'vue-property-decorator';
import {PoliciesServices} from '@/components/policies/PoliciesServices';

@Component
export default class PolicyStatusChip extends Vue {
  @Prop({required: true}) status!: string;

  get label() {
    return PoliciesServices.getPolicyStatusLabel(this.status);
  }

  get color() {
    return PoliciesServices.getPolicyStatusColor(this.status);
  }

  get icon() {
    return PoliciesServices.getPolicyStatusIcon(this.status);
  }
}
