type StatusMap = Record<string, string>;

export class PoliciesServices {

    private static baseLabels: StatusMap = {
        IN_QUEUE_FOR_CHECK: 'В очереди на проверку',
        ACTIVE: 'Есть активный полис',
        NO_ACTIVE: 'Нет активных полисов',
        NOT_FOUND: 'Полиса не найдены',
    };

    private static baseColors: StatusMap = {
        IN_QUEUE_FOR_CHECK: 'orange',
        ACTIVE: 'green',
        NO_ACTIVE: 'red',
        NOT_FOUND: 'grey',
    };

    private static baseIcons: StatusMap = {
        IN_QUEUE_FOR_CHECK: 'mdi-timer-sand',
        ACTIVE: 'mdi-check-circle',
        NO_ACTIVE: 'mdi-cancel',
        NOT_FOUND: 'mdi-alert-circle',
    };

    // ---------- Специфичные для полисов ----------

    private static policyExtraLabels: StatusMap = {
        ACTIVE: 'Активный',
        WAITING_ACTIVATION: 'Действия полиса не наступило',
        EXPIRED: 'Срок действия полиса истёк',
    };


    private static policyExtraColors: StatusMap = {
        ACTIVE: 'green',
        WAITING_ACTIVATION: 'blue',
        EXPIRED: 'red lighten-1',
    };

    private static policyExtraIcons: StatusMap = {
        ACTIVE: 'mdi-check-circle',
        WAITING_ACTIVATION: 'mdi-clock-outline',
        EXPIRED: 'mdi-calendar-remove',
    };


    // ---------- Методы транспорта ----------

    static getTransportStatusLabel(status: string): string {
        return this.baseLabels[status] || 'Неизвестный статус';
    }

    static getTransportStatusColor(status: string): string {
        return this.baseColors[status] || 'black';
    }

    static getTransportStatusIcon(status: string): string {
        return this.baseIcons[status] || 'mdi-help-circle';
    }

    static getPolicyStatusLabel(status: string): string {
        return this.policyExtraLabels[status] || 'Неизвестный статус';
    }

    static getPolicyStatusColor(status: string): string {
        return this.policyExtraColors[status] || 'black';
    }

    static getPolicyStatusIcon(status: string): string {
        return this.policyExtraIcons[status] || 'mdi-help-circle';
    }

    static get baseStatusOptions(): { text: string; value: string }[] {
        return Object.entries(this.baseLabels).map(([value, text]) => ({ text, value }));
    }
}
