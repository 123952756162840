
import {Vue, Component} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import Http from '@/Http';
import TransportStatusChip from '@/components/policies/TransportStatusChip.vue';
import PolicyStatusChip from '@/components/policies/PolicyStatusChip.vue';
import FinesExportExcel from '@/components/fines/FinesExportExcel.vue';
import {PoliciesServices} from '@/components/policies/PoliciesServices';

@Component({
  components: {FinesExportExcel, PolicyStatusChip, TransportStatusChip}
})
export default class PoliciesTable extends Vue {
  loading = false;
  items: any[] = [];

  filterData: any = {};
  statuses: any = [];
  regNumbers = AppStore.regNumbers;

  created() {
    this.load();
    this.statuses = PoliciesServices.baseStatusOptions;
  }

  load() {
    AppStore.showLoader();
    this.loading = true;
    Http.post('v2/policies', this.filterData).then((response: any) => {
      this.items = response;
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }


}
