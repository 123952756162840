
import {Vue, Component, Prop} from 'vue-property-decorator';
import {PoliciesServices} from '@/components/policies/PoliciesServices';


@Component
export default class TransportStatusChip extends Vue {
  @Prop({required: true}) status!: string;

  get label() {
    return PoliciesServices.getTransportStatusLabel(this.status);
  }

  get color() {
    return PoliciesServices.getTransportStatusColor(this.status);
  }

  get icon() {
    return PoliciesServices.getTransportStatusIcon(this.status);
  }
}
